import React from 'react';
import { render } from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import App from './components/App';
import Blocks from './components/Blocks';
import ConductTransaction from './components/ConductTransaction';
import TransactionPool from './components/TransactionPool';
import CertificateList from './components/CertificateList';
import Certificate1 from './components/a506530769b29b0baea7837cbce52a5a17363f87ceff940e7945fbcf90234847';
import Certificate2 from './components/b1306939486282a5299956643b1a9f985826267a34ca0528893a01c3aba80c52';
import './index.css';

render(
  <Router history={history}>
    <Switch>
      <Route exact path='/' component={App} />
      <Route path='/blocks' component={Blocks} />
      <Route path='/conduct-transaction' component={ConductTransaction} />
      <Route path='/transaction-pool' component={TransactionPool} />
      <Route path='/certificatelist' component={CertificateList} />
      <Route path='/a506530769b29b0baea7837cbce52a5a17363f87ceff940e7945fbcf90234847' component={Certificate1} />
      <Route path='/b1306939486282a5299956643b1a9f985826267a34ca0528893a01c3aba80c52' component={Certificate2} />
    </Switch>
  </Router>,
  document.getElementById('root')
);
