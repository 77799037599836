import React, { Component } from 'react';
import certificate1 from '../assets/certificate1.png';
import certificate2 from '../assets/certificate2.png';
import logo2 from '../assets/logo2.png';
import { Link } from 'react-router-dom';




class CertificateList extends Component {
  state = { walletInfo: {} };
  render() {
    const { address, balance } = this.state.walletInfo;
    return (
      <div className='CertificateList'>
      <Link to='/'>Home</Link>
      <h3>Certificate's Listing</h3>
      <br />
      <br />

        <div><Link to='/a506530769b29b0baea7837cbce52a5a17363f87ceff940e7945fbcf90234847'>1.ต่อฤทธิ์ บุณยเกียรติ</Link></div>
        <div><Link to='/b1306939486282a5299956643b1a9f985826267a34ca0528893a01c3aba80c52'>2.คมเดช บุญประเสริฐ</Link></div>

        <br />
        <div>
          SIAM UNIVERSITY's e-Certificate Blockchain System...
        </div>
          <br />
          <br />
        <div>Powered by</div><img className='logo2' src={logo2}></img><div>New Wave Consulting Group</div>
      </div>
    );
  }
}
export default CertificateList;
