import React from 'react';

const Transaction = ({ transaction }) => {
  const { input, outputMap } = transaction;
  const recipients = Object.keys(outputMap);

  return (
    <div className='Transaction'>
      {
        recipients.map(recipient => (
          <div key={recipient}>
            Data: {`${recipient.substring(0, 1000)}...`} | Sent: {outputMap[recipient]}
          </div>
        ))
      }
      <div>Account: {`${input.address.substring(0, 20)}...`} | Balance: {input.amount}</div>
    </div>
  );
}

export default Transaction;
