import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';




class App extends Component {
  state = { walletInfo: {} };

  componentDidMount() {
    fetch(`${document.location.origin}/api/wallet-info`)
      .then(response => response.json())
      .then(json => this.setState({ walletInfo: json }));
  }
  render() {
    const { address, balance } = this.state.walletInfo;
    return (
      <div className='App'>
        <img className='logo' src={logo}></img>
        <br />
        <div>
          Welcome to Thailand's e-Certificate Blockchain System...
        </div>
        <br />
        <div><Link to='/blocks'>Blocks Explorer</Link></div>
        <br />
        <div className='WalletInfo'>
          <div>Address: {address}</div>
          <div>Balance: {balance}</div>
        </div>
          <br />
          <div>Powered by</div>
          <img className='logo3' src={logo3}></img>
          <img className='logo2' src={logo2}></img><div>New Wave Consulting Group</div>
        </div>
    );
  }
}
export default App;
