import React, { Component } from 'react';
import certificate1 from '../assets/certificate1.png';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';
import { Link } from 'react-router-dom';




class Certificate1 extends Component {
  state = { walletInfo: {} };
  render() {
    const { address, balance } = this.state.walletInfo;
    return (
      <div className='Certificate1'>
      <h3>Your Certificate's Picture</h3>
      <br />
      <br />
        <img className='certificate1' src={certificate1}></img>
        <div>
          นายต่อฤทธิ์ บุณยเกียรติ
        </div>
        <br />
        <div>
          Thailand's e-Certificate Blockchain System...
        </div>
          <br />
          <div><Link to='/api/blocks/2'>Your Blockchain Data</Link></div>
          <br />
        <div>Powered by</div>
        <br />
        <img className='logo3' src={logo3}></img>
        <br />
        <img className='logo2' src={logo2}></img><div>New Wave Consulting Group</div>
      </div>
    );
  }
}
export default Certificate1;
